import { Message, MessageBox } from 'element-ui'
import { AccountApi } from '@/api'

const handleBeforeClose = (action, instance, done) => {
  if (action === 'confirm') {
    if (instance.inputValue) {
      AccountApi.validDealPassword({
        password: instance.inputValue
      }).then((status) => {
        if (status) {
          Message.success('验证成功')
          done()
        } else {
          Message.error('验证失败')
        }
      }).catch(() => {
        Message.error('验证失败')
      })
    } else {
      Message.error('请输入交易密码')
    }
  } else {
    done()
  }
}

/**
 * 验证交易密码
 */
export const validDealPassword = () => {
  return new Promise((resolve) => {
    MessageBox.prompt('', '操作验证', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPlaceholder: '请输入交易密码',
      inputType: 'password',
      showClose: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      beforeClose: handleBeforeClose
    }).then(({ value }) => {
      resolve(value)
    }).catch(() => {
    })
  })
}
