<template>
  <div>
    <page-header></page-header>
    <div class="layout-main-body">
      <el-card shadow="never" v-loading="pageLoading">
        <div class="el-form-item el-form-item--small">
          <label class="el-form-item__label">帐户名称</label>
          <div class="el-form-item__content">{{ currentAccount.name }}</div>
        </div>
        <div class="el-form-item el-form-item--small">
          <label class="el-form-item__label">等级</label>
          <div class="el-form-item__content">{{ currentAccount.equityName }}</div>
        </div>
        <div class="el-form-item el-form-item--small">
          <label class="el-form-item__label">折扣</label>
          <div class="el-form-item__content">{{ currentAccount.discount }}</div>
        </div>
        <div class="el-form-item el-form-item--small">
          <label class="el-form-item__label">开通时间</label>
          <div class="el-form-item__content">{{ currentAccount.creationTime }}</div>
        </div>
        <div class="el-form-item el-form-item--small">
          <label class="el-form-item__label">登录密码</label>
          <div class="el-form-item__content account-content">
            <div class="password"><span>******</span></div>
            <div>
              <el-link type="primary" class="account-link account-link-ml" @click.prevent="handleChangePassword">修改
              </el-link>
            </div>
          </div>
        </div>
        <div class="el-form-item el-form-item--small">
          <label class="el-form-item__label">交易密码</label>
          <div class="el-form-item__content account-content">
            <div class="password"><span>{{ currentAccount.dealPassword }}</span></div>
            <div>
              <div v-if="currentAccount.dealPassword">
                <el-link type="primary" class="account-link account-link-ml"
                         @click.prevent="handleGotoPayPwdUpdatePage">修改
                </el-link>
                <el-divider direction="vertical"></el-divider>
                <el-link type="primary" class="account-link" @click.prevent="handleGotoPayPwdResetPage">忘记密码
                </el-link>
              </div>
              <el-link type="primary" class="account-link account-link-ml" v-else
                       @click.prevent="handleGotoPayPwdAddPage">未设置
              </el-link>
            </div>
          </div>
        </div>
        <div class="el-form-item el-form-item--small">
          <label class="el-form-item__label">帐户余额</label>
          <div class="el-form-item__content account-content">
            <div class="text"><span class="number">{{ currentAccount.balance }}</span>元</div>
            <div class="btn-group">
              <el-button type="primary" size="mini" @click="handleRechargeBalance">充值</el-button>
              <el-button type="text" @click="handleGotoWithdrawPage">提现</el-button>
              <el-button type="text" @click="handleGotoBalanceLogPage">明细</el-button>
            </div>
          </div>
        </div>
        <div class="el-form-item el-form-item--small">
          <label class="el-form-item__label">VIP年卡数</label>
          <div class="el-form-item__content account-content">
            <div class="text">
              <el-popover
                  placement="top"
                  width="200"
                  trigger="hover"
                  :content="`赠送：${currentAccount.freeVipCardCount} 个，付费：${currentAccount.payVipCardCount} 个`">
                <span slot="reference" class="number total-card">{{ currentAccount.totalVipCardCount }}</span>
              </el-popover>
              个
            </div>
            <div class="btn-group">
              <el-button type="primary" size="mini" @click="handleRechargeVipCard">购买</el-button>
              <el-button type="text" @click="handleGotoVipCardLogPage">明细</el-button>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { AccountApi } from '@/api'
import { validDealPassword } from '@/utils/password-valid'

export default {
  name: 'AccountInfo',
  data () {
    return {
      currentAccount: {},
      pageLoading: false
    }
  },
  methods: {
    async initData () {
      this.pageLoading = true
      this.currentAccount = await AccountApi.getAccount()
      this.pageLoading = false
    },
    handleGotoBalanceLogPage () {
      this.$router.push('/account/balance/log')
    },
    handleGotoVipCardLogPage () {
      this.$router.push('/account/vip/log')
    },
    handleGotoPayPwdAddPage () {
      this.$router.push('/account/deal/password')
    },
    handleGotoPayPwdUpdatePage () {
      validDealPassword().then(() => {
        this.$router.push('/account/deal/password')
      })
    },
    handleGotoPayPwdResetPage () {
      this.$router.push('/account/deal/password/reset')
    },
    handleRechargeVipCard () {
      this.$router.push('/account/recharge/vip/card')
    },
    handleRechargeBalance () {
      this.$router.push('/account/recharge/balance')
    },
    handleGotoWithdrawPage () {
      this.$router.push('/account/withdraw/list')
    },
    handleChangePassword () {
      this.$router.push('/sys/user/password/modify')
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style lang="less" scoped>
.number {
  height: 38px;
  margin-top: 4px;
  margin-bottom: 0;
  overflow: hidden;
  color: rgba(0, 0, 0, .85);
  font-size: 24px;
  line-height: 38px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  margin-right: 4px;
}

.account-link {
  font-size: 12px;
}

.account-link-ml {
  margin-left: 5px;
}

.el-form-item__label {
  width: 200px;
  color: #999999;
}

.el-form-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.account-content {
  display: flex;
  align-items: center;

  .text {
    min-width: 100px;
  }

  .btn-group {
    margin-left: 20px;
  }
}

.password {
  padding-top: 4px;
  margin-right: 10px;
}

.card-text {
  width: 100px;
}

.total-card {
  border-bottom: 1px dashed;
  cursor: pointer;
}

</style>
